import "./App.css";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";

import AskAI from "./page/AskAI";
import CollectAnswer from './page/CollectAnswer';
import NotFound from "./page/NotFound";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/collect_answer" element={<CollectAnswer />} />
          <Route path="/quality_control" element={<AskAI />} />

          {/* 404 Not Found page for unmatched routes */}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </div>
  );
}
export default App;
