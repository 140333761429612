import React from "react";

const NotFound = () => {
  return (
    <div style={styles.container}>
      <h1 style={styles.title}>404 - Page Not Found</h1>
      <p style={styles.message}>The page you are looking for does not exist.</p>
    </div>
  );
};

const styles = {
  container: {
    textAlign: "center",
    padding: "50px",
  },
  title: {
    fontSize: "48px",
    color: "#333",
  },
  message: {
    fontSize: "18px",
    color: "#666",
  },
  link: {
    marginTop: "20px",
    display: "inline-block",
    fontSize: "16px",
    color: "#007bff",
    textDecoration: "underline",
  },
};

export default NotFound;
